<template>
  <div class="text-center">
    <v-snackbar v-model="shouldShow" :timeout="timeout">
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    timeout: 2000
  }),
  props: {
    visible: Boolean,
    message: String
  },
  computed: {
    shouldShow: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("hide");
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
