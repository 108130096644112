<template>
  <v-speed-dial
    class="social-media-buttons"
    v-model="dialShare"
    absolute
    right
    bottom
    direction="left"
    open-on-hover
    fixed
  >
    <template v-slot:activator>
      <v-btn 
      fab bottom small color="primary"
      aria-label="Social Media Button"
      >
        <v-icon v-if="dialShare">mdi-close</v-icon>
        <v-icon v-else>mdi-share-variant</v-icon>
      </v-btn>
    </template>
    <v-btn
      dark
      fab
      bottom
      color="blue darken-7"
      small
      href="https://www.linkedin.com/in/mohammad-elghali/"
      aria-label="LinkedIn"
      target="_blank"
    >
      <v-icon>mdi-linkedin</v-icon>
    </v-btn>
    <v-btn
      dark
      fab
      bottom
      color="red darken-7"
      small
      href="https://www.instagram.com/mghali1/"
      aria-label="Instagram"
      target="_blank"
    >
      <v-icon>mdi-instagram</v-icon>
    </v-btn>
    <v-btn
      dark
      fab
      bottom
      color="grey"
      small
      href="mailto:mohammadghali97@gmail.com"
      aria-label="Email"
      target="_blank"
    >
      <v-icon>mdi-email</v-icon>
    </v-btn>
    <v-btn
      dark
      fab
      bottom
      color="tertiary"
      small
      href="https://github.com/mghali1"
      aria-label="Github"
      target="_blank"
    >
      <v-icon>mdi-github</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  data() {
    return {
      dialShare: false,
      pageUrl: "https://mohamadghali.com"
    };
  }
};
</script>

<style lang="scss" scoped>
.social-media-buttons {
  z-index: 10000;
}
</style>
