<template>
  <v-footer dark padless>
    <v-card flat tile class="pink lighten-1 white--text text-center flex">
      <v-card-text>
        <v-btn
          v-for="item in icons"
          :aria-label="item.label"
          :key="item.icon"
          :href="item.link"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} —
        <strong
          >Developed with <v-icon>mdi-heart</v-icon> by Mohamad EL Ghali in
          Beirut, Lebanon</strong
        >
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      icons: [
        {
          label:"Github",
          icon: "mdi-github",
          link: "https://github.com/mghali1"
        },
        {
          label:"Instagram",
          icon: "mdi-instagram",
          link: "https://www.instagram.com/mghali1/"
        },
        {
          label:"LinkedIn",
          icon: "mdi-linkedin",
          link: "https://www.linkedin.com/in/mohammad-elghali/"
        },
        {
          label:"Email",
          icon: "mdi-email",
          link: "mailto:mohammadghali97@gmail.com"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped></style>
