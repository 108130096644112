import axios from "axios";

export default {
  methods: {
    get(url, params = {}, onSuccess = () => {}, onFailure = () => {}) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        url: url,
        data: this.getParams(params)
      })
        .then(response => {
          if (response.status === 200) {
            onSuccess(response);
          } else {
            onFailure(response.status);
          }
        })
        .catch(error => {
          onFailure(error);
        });
    },
    post(url, params = {}, onSuccess = () => {}, onFailure = () => {}) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        url: url,
        data: this.getParams(params)
      })
        .then(response => {
          if (response.status === 200) {
            onSuccess(response);
          } else {
            onFailure(response.status);
          }
        })
        .catch(error => {
          onFailure(error);
        });
    },
    getParams(data) {
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(data)) {
        params.append(key, value);
      }
      return params;
    }
  }
};
