<template>
  <v-container id="home">
    <div class="banner_shape hidden-md-and-down"></div>
    <v-row class="text-center" align="center" justify="center">
      <v-col col="6" class="mb-4 welcoming-screen-text">
        <h1 class="quick-intro-text display-3 font-weight-bold mb-3">
          Hello, I'm
        </h1>
        <h1 class="quick-intro-text display-3 font-weight-bold mb-3">
          Mohamad El-Ghali
        </h1>
        <vue-typed-js
          :strings="specializations"
          :loop="true"
          :typeSpeed="100"
          :backSpeed="100"
        >
          <p class="display-2 font-weight-regular dynamic-text">
            I specialize in <span class="typing pink--text"></span>
          </p>
        </vue-typed-js>
        <v-row class="my-2 buttons-row">
          <v-col :cols="getColsForButtons" class="d-flex justify-center">
            <v-btn
              class="welcomeing-screen-button"
              href="https://mohamadghali.com/Mohamad%20El%20Ghali%20Resume.pdf"
              download
              large
              color="success"
              elevation="9"
              dark
              floating
            >
              <v-icon left>mdi-file-document-multiple</v-icon>
              Download Resume
            </v-btn>
          </v-col>
          <v-col :cols="getColsForButtons" class="d-flex justify-center">
            <v-btn
              href="https://www.linkedin.com/in/mohammad-elghali/"
              absolute
              class="welcomeing-screen-button"
              large
              color="info"
              elevation="9"
              dark
              floating
            >
              <v-icon left>mdi-linkedin</v-icon>
              View LinkedIn
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class=" hidden-md-and-down">
        <v-img
          data-aos="flip-right"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="750"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          :lazy-src="require('../assets/mohamadElGhali2_lazy.png')"
          :src="require('../assets/mohamadElGhali2.png')"
          class="circular-image"
          width="500"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueTypedJs from "vue-typed-js/src/components/VueTypedJs.vue";

export default {
  name: "Welcoming Screen",
  components: {
    VueTypedJs
  },
  computed: {
    getColsForButtons() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        default:
          return 6;
      }
    }
  },
  data() {
    return {
      specializations: [
        "Web Design",
        "Web Development",
        "Technical Consultation",
        "Software Engineering"
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.welcomeing-screen-button {
  margin: 5px;
}
.welcoming-screen-text {
  z-index: 1;
  word-wrap: break-word;
}
.dynamic-text {
  min-height: 3em;
}
.banner_shape {
  position: absolute;
  right: -16%;
  margin-top: -12px;
  overflow: hidden;
  background-color: #e91e625b;
  width: 60%;
  height: 500px;
  z-index: 0;
  -moz-transform: skewX(-20deg);
  -webkit-transform: skewX(-20deg);
  transform: skewX(-20deg);
}
.buttons-row {
  padding-bottom: 10px;
}
</style>
