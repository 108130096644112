<template>
  <v-card class="main-navigation-bar mx-auto">
    <v-app-bar color="pink" dark dense elevate-on-scroll fixed>
      <v-app-bar-nav-icon @click="drawer = true" class="hidden-lg-and-up">
      </v-app-bar-nav-icon>

      <v-tabs align-with-title hide-slider active-class="dont-show-active" class="hidden-md-and-down">
        <v-tab
          v-for="item in menuItems"
          :key="item.name"
          @click="scrollMeTo(item.navigateTo)"
        >
          <v-icon left>{{ item.icon }}</v-icon> {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-navigation-drawer
        light
        v-model="drawer"
        fixed
        :height="$vuetify.breakpoint.height"
        width="256"
        class="mx-auto"
      >
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item
              v-for="item in menuItems"
              :key="item.name"
              :href="item.navigateTo"
            >
              <v-list-item-icon @click="scrollMeTo(item.navigateTo)">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title> {{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>
  </v-card>
</template>
<script>
import { scrollTo } from "vue-scrollto";

export default {
  name: "Navigation Bar",
  data() {
    return {
      drawer: false,
      group: null,
      menuItems: [
        {
          name: "Home",
          navigateTo: "#home",
          icon: "mdi-home"
        },
        {
          name: "Education",
          navigateTo: "#education-section",
          icon: "mdi-school"
        },
        {
          name: "Work Experience",
          navigateTo: "#work-experience-section",
          icon: "mdi-briefcase"
        },
        {
          name: "Portfolio",
          navigateTo: "#portfolio-section",
          icon: "mdi-code-tags"
        },
        {
          name: "Contact",
          navigateTo: "#contact-me-section",
          icon: "mdi-email"
        }
      ]
    };
  },
  watch: {
    group() {
      this.drawer = false;
    }
  },
  methods: {
    scrollMeTo(id) {
      var options = {
        easing: "ease-in",
        lazy: false,
        offset: -60,
        force: true,
        cancelable: true,
        x: false,
        y: true
      };
      scrollTo(id, 500, options);
    }
  }
};
</script>

<style lang="scss" scoped>
.main-navigation-bar {
  z-index: 200;
  margin-bottom: 48px;
}
.dont-show-active{
  color: rgba(255, 255, 255, 0.6)!important;
}
</style>
