<template>
  <section id="contact-me-section" class="mb-3">
    <v-row class="d-flex justify-center">
      <v-col :cols="getColsCard">
        <v-card class="indigo card-info" dark>
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-row>
              <v-col :cols="getColsForCardLeft">
                <v-card-title class="headline">Mohamad El-Ghali</v-card-title>
                <v-card-subtitle>Software Engineer</v-card-subtitle>
                <div class="ml-4">
                  <p>Email: mohammadghali97@gmail.com</p>
                  <p>Mobile: +96181791570</p>
                  <p>Address: Verdun, Beirut, Lebanon</p>
                </div>
              </v-col>
              <v-col :cols="getColsForCardRight">
                <ContactMeDialog ref="contactForm" @send="sendEmailToSelf">
                </ContactMeDialog>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ContactMeDialog from "@/components/ContactMeDialog.vue";

import AxiosMixin from "@/mixins/AxiosMixin.js";

export default {
  components: {
    ContactMeDialog
  },
  mixins: [AxiosMixin],
  data() {
    return {
      showContactMeDialog: false
    };
  },
  computed: {
    getColsCard() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 10;
        case "lg":
          return 8;
        case "xl":
          return 8;
        default:
          return 8;
      }
    },
    getColsForCardLeft() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 4;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 3;
        default:
          return 3;
      }
    },
    getColsForCardRight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 8;
        case "md":
          return 8;
        case "lg":
          return 9;
        case "xl":
          return 9;
        default:
          return 12;
      }
    }
  },
  methods: {
    sendEmailToSelf(values) {
      if (values) {
        this.$emit("show-snack-bar", "Sending..");
        this.showContactMeDialog = false;
        this.post(
          "https://www.pitchkings.net/website/PHP/sendEmail.php",
          {
            email: "mohammadghali97@gmail.com",
            subject: `New message from mohamadghali.com user: ${values.email}`,
            message: `Name: ${values.name} | Email: ${values.email} | Message: ${values.message}`
          },
          this.onSuccess,
          this.onFailure
        );
        this.onSuccess();
      }
    },
    onSuccess() {
      this.$emit("show-snack-bar", "Message sent successfully");
      this.$refs.contactForm.clearInputs();
    },
    onFailure(result) {
      this.$emit("show-snack-bar", `Message failed to send: ${result}`);
    }
  }
};
</script>

<style lang="scss" scoped>
#contact-me-section {
  padding-bottom: 25px;
}
</style>
