<template>
  <section id="work-experience-section">
    <h2 class="title text-center section-title">Work Experience</h2>
    <h3 class="text-center">Chronological Order</h3>
    <v-skeleton-loader
      v-if="isLoading"
      class="mx-auto my-4"
      max-width="970"
      type="card, article"
    ></v-skeleton-loader>
    <v-row class="d-flex justify-center">
      <v-spacer class="hidden-md-and-down"></v-spacer>
      <v-col :cols="getColsForTimeline" class="d-flex justify-center">
        <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
            v-for="(item, i) in workExperience"
            :key="i"
            :color="item.color"
            :icon="item.icon"
            fill-dot
            class="work-timeline"
          >
            <v-card :color="item.color" dark>
              <v-card-title class="title">
                {{ item.company }}
              </v-card-title>
              <v-card-subtitle class="subtitle">
                <span class="job-position-text"> {{ item.position }} </span> <br/>
                {{ getDate(item.startDate, item.endDate) }}
              </v-card-subtitle>
              <v-card-text class="white text--primary bullet-points">
                <p v-for="point in item.descriptionPoints" :key="point">
                  - {{ point }}
                </p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
      <v-spacer class="hidden-md-and-down"></v-spacer>
    </v-row>
  </section>
</template>

<script>
import moment from "moment";
import AxiosMixin from "@/mixins/AxiosMixin.js";

export default {
  mixins: [AxiosMixin],
  created() {
    const temp2 = JSON.stringify(this.workExperience);
    const temp = {
      abc: temp2
    };
    this.post(
      "https://mohamadghali.com/services/get_work_experience.php",
      temp,
      response => {
        this.isLoading = false;
        this.workExperience = JSON.parse(response.data);
      }
    );
  },
  data: () => ({
    isLoading: true,
    workExperience: []
  }),
  computed: {
    getColsForTimeline() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 8;
        case "xl":
          return 8;
        default:
          return 11;
      }
    }
  },
  methods: {
    getDate(startDate, endDate) {
      startDate = moment(String(startDate), "DD/MM/YYYY");
      const startDateString = startDate.format("MMM-YYYY");
      if (endDate) {
        endDate = moment(String(endDate), "DD/MM/YYYY");
        const endDateString = endDate.format("MMM-YYYY");
        const duration = endDate.diff(startDate, "milliseconds");
        const years = moment.duration(duration).years();
        const months = moment.duration(duration).months();
        const difference =
          years > 0 ? `( ${years}Y ${months}M )` : `( ${months}M )`;
        return `${startDateString} - ${endDateString} ${difference}`;
      }
      const today = moment();
      const duration = today.diff(startDate, "milliseconds");
      const years = moment.duration(duration).years();
      const months = moment.duration(duration).months();
      const difference =
        years > 0 ? `( ${years}Y ${months}M )` : `( ${months}M )`;
      return `${startDateString} - Present ${difference}`;
    }
  }
};
</script>

<style scoped>
.section-title {
  margin-top: 25px;
}
.bullet-points {
  padding-top: 25px;
}
.job-position-text{
  color: white;
  font-size: 1.25em;
}
</style>
