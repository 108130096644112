<template>
  <section id="portfolio-section">
    <PortfolioDialog
      :isPortfolioDialogVisible="isPortfolioDialogVisible"
      :item="selectedPorfolio"
      @hide-dialog="isPortfolioDialogVisible = false"
    ></PortfolioDialog>
    <div class="d-flex justify-center">
      <h2 class="title text-center section-title">Portfolio</h2> 
      <v-btn
        class="mx-3 my-6"
        aria-label="Github"
        href="https://github.com/mghali1"
        target="_blank"
        fab
        dark
        small
        color="black"
      >
        <v-icon dark>
          mdi-github
        </v-icon>
      </v-btn>
    </div>
    <div class="d-flex justify-space-around">
      <v-row
        class="col-9"
        data-aos="flip-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="750"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
      >
        <template v-for="(portfolio, n) in portfolioList">
          <v-col :key="portfolio.name" class="d-flex child-flex" :cols="getCols">
            <v-hover v-slot="{ hover }">
              <v-card :elevation="hover ? 12 : 2">
                <v-img
                  :src="getImgUrl(portfolio.coverImageSrc)"
                  aspect-ratio="1"
                  class="portfolio-image"
                  @load="() => {onImageLoad(n)}"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="title portfolio-item-title d-flex justify-center">
                  <div>{{ portfolio.name }}</div>
                </v-card-title>
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="pink">
                    <v-btn @click="showPorfolioDialog(n)">
                      See more info
                    </v-btn>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </v-hover>
          </v-col>
        </template>
      </v-row>
    </div>
    <v-row>
    <v-col col="12">
      <v-skeleton-loader
        v-if="isLoading"
        class="mx-auto my-4"
        max-width="970"
        type="card, article"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
  </section>
</template>

<script>
import PortfolioDialog from "@/components/PortfolioDialog.vue";
import AxiosMixin from "@/mixins/AxiosMixin.js";

export default {
  components: {
    PortfolioDialog
  },
  metaInfo() {
    return {
      meta: [
        {
          name:`Description`,
          content: `Pitchkings Pitch Kings Mappable online mapping solution accessible petsapp pets app password manager al halabi oman al-halabi-oman BASR basr saida Koopa mattress`
        }
      ]
    };
  },
  mixins: [AxiosMixin],
  created() {
    this.get(
      "https://mohamadghali.com/services/get_portfolio.php",
      {},
      response => {
        this.portfolioList = JSON.parse(response.data);
        this.isWebserviceLoading = false;
      }
    );
  },
  computed: {
    getCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 4;
        case "lg":
          return 3;
        case "xl":
          return 3;
        default:
          return 3;
      }
    },
    isLoading() {
      return this.isWebserviceLoading || this.imagesAreLoading;
    }
  },
  data() {
    return {
      isWebserviceLoading: true,
      imagesAreLoading: true,
      availableAnimations: ["fade-up", "fade-right"],
      isPortfolioDialogVisible: false,
      selectedPorfolio: {},
      portfolioList: []
    };
  },
  methods: {
    showPorfolioDialog(index) {
      this.selectedPorfolio = this.portfolioList[index];
      this.isPortfolioDialogVisible = true;
    },
    getImgUrl(imageName) {
      return require(`../assets/${imageName}`);
    },
    getRandomAnimation() {
      const randomIndex = Math.floor(
        Math.random() * this.availableAnimations.length - 1
      );
      return this.availableAnimations[randomIndex];
    },
    onImageLoad(index) {
      this.portfolioList[index].isLoaded = true;
      const unloadedIndex = this.portfolioList.findIndex(item => !item.isLoaded);
      if (unloadedIndex === -1) {
        this.imagesAreLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scopped>
#portfolio-section {
  padding-top: 15px;
  padding-bottom: 35px;
}
.portfolio-item-title {
  text-align: center;
}
.section-title {
  margin-top: 30px;
}
</style>
