<template>
  <v-card light elevation="12">
    <v-card-title>
      <span class="headline">Contact Me</span>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name*"
                type="text"
                v-model="name"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Email*"
                type="email"
                v-model="email"
                :rules="emailRules"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Message*"
                v-model="message"
                :rules="messageRules"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <small>*indicates required field</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="$emit('send', values)">
        Send
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      name: "",
      email: "",
      message: "",
      nameRules: [
        v => !!v || "Name is required",
        v => v.length < 100 || "Name must be less than 100 characters"
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      messageRules: [
        v => !!v || "Message is required",
        v =>
          (v.length > 25 && v.length <= 5000) ||
          "Message must be between 25 and 5000 characters"
      ]
    };
  },
  computed: {
    values() {
      if (this.valid) {
        return {
          name: this.name.trim(),
          email: this.email.trim(),
          message: this.message.trim()
        };
      } else {
        this.$refs.form.validate();
      }
      return null;
    }
  },
  methods: {
    clearInputs() {
      this.$refs.form.reset();
    }
  }
};
</script>

<style lang="scss" scoped></style>
