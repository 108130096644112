<template>
  <div class="skills">
    <v-row class="d-flex justify-center">
      <v-col :cols="getCols">
        <v-card>
          <v-card-title class="pa-4 white--text green">
            Skill set
          </v-card-title>
          <v-sheet class="pa-4 green">
            <v-text-field
              v-model="search"
              label="Search skill set"
              dark
              flat
              solo-inverted
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
          </v-sheet>
          <v-card-text>
            <v-treeview
              :items="skills"
              :search="search"
            >
              <template v-slot:prepend="{ item }">
                <v-icon
                  v-text="`${item.icon ? item.icon : 'mdi-code-braces'}`"
                ></v-icon>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    getCols() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 12;
        case "md":
          return 10;
        case "lg":
          return 8;
        case "xl":
          return 8;
        default:
          return 8;
      }
    }
  },
  data: () => ({
      skills: [
        {
          id: "front-end",
          name: "Front End",
          icon: "mdi-format-paint",
          children: [
            {
              id: "vue-js",
              name: "Vue.JS",
              icon: "mdi-vuejs"
            },
            {
              id: "vuetify",
              name: "Vuetify",
              icon: "mdi-vuetify"
            },
            {
              id: "html",
              name: "HTML",
              icon: "mdi-language-html5"
            },
            {
              id: "css",
              name: "CSS",
              icon: "mdi-language-css3"
            },
            {
              id: "javascript",
              name: "JavaScript",
              icon: "mdi-language-javascript"
            },
            {
              id: "jquery",
              name: "JQuery",
              icon: "mdi-jquery"
            },
            {
              id: "bootstrap",
              name: "Bootstrap",
              icon: "mdi-bootstrap"
            }
          ],
        },
        {
          id: "backend",
          name: "Back End",
          icon: "mdi-brain",
          children: [
            {
              id: "java",
              name: "Java",
              icon: "mdi-language-java"
            },
            {
              id: "csharp",
              name: "C# (C Sharp)",
              icon: "mdi-language-csharp"
            },
            {
              id: "php",
              name: "PHP",
              icon: "mdi-language-php"
            }
          ],
        },
        {
          id: "database",
          name: "Database",
          icon: "mdi-database",
          children: [
            {
              id: "sql",
              name: "SQL",
              icon: "mdi-database"
            },
            {
              id: "mysql",
              name: "MySQL",
              icon: "mdi-database"
            },
            {
              id: "postgres",
              name: "PostgreSQL",
              icon: "mdi-database"
            }
          ],
        },
        {
          id: "tools",
          name: "Tools and technologies",
          icon: "mdi-tools",
          children: [
            {
              id: "git",
              name: "Git",
              icon: "mdi-git"
            },
            {
              id: "gitlab",
              name: "GitLab",
              icon: "mdi-gitlab"
            },
            {
              id: "jira",
              name: "Jira",
              icon: "mdi-jira"
            },
            {
              id: "wordpres",
              name: "Wordpress",
              icon: "mdi-wordpress"
            },
            {
              id: "woocommerce",
              name: "WooCommerce",
              icon: "mdi-cash-usd-outline"
            },
            {
              id: "virtualbox",
              name: "VirtualBox",
              icon: "mdi-application"
            },
            {
              id: "vmware",
              name: "VMWare",
              icon: "mdi-application"
            },
            {
              id: "rfid",
              name: "RFID / RFID reader",
              icon: "mdi-cellphone-wireless"
            },
            {
              id: "barcode",
              name: "Barcode / Barcode reader",
              icon: "mdi-barcode"
            }
          ],
        },
        {
          name: "Operating Systems",
          children: [
            {
              id: "windows",
              name: "Windows",
              icon: "mdi-microsoft-windows"
            },
            {
              id: "macos",
              name: "MacOS",
              icon: "mdi-apple"
            },
            {
              id: "linux",
              name: "Linux",
              icon: "mdi-linux",
              children: [
                {
                  id: "ubuntu",
                  name: "Ubuntu",
                  icon: "mdi-ubuntu"
                },
                {
                  id: "xubuntu",
                  name: "Xubuntu",
                  icon: "mdi-linux"
                },
                {
                  id: "kali",
                  name: "Kali",
                  icon: "mdi-linux"
                },
              ]
            }
            ]
          },
          {
            id: "personal",
            name: "Personal",
            icon: "mdi-human-handsup",
            children: [
              {
                id: "fast-learner",
                name: "Fast learner",
                icon: "mdi-clock-fast"
              },
              {
                id: "knowledge-seeker",
                name: "Knowledge seeker",
                icon: "mdi-book-open-variant"
              },
              {
                id: "critical-thinker",
                name: "Critical thinker",
                icon: "mdi-brain"
              },
              {
                id: "great-listener",
                name: "Great listener",
                icon: "mdi-ear-hearing"
              },
              {
                id: "team-player",
                name: "Team player",
                icon: "mdi-human-male-male"
              },
              {
                id: "constant-deliverer",
                name: "Constant Deliverer",
                icon: "mdi-file-code"
              },
              {
                id: "improvement-driven",
                name: "Improvement driven",
                icon: "mdi-chart-box-plus-outline"
              }
            ],
          },
      ],
      open: [0],
      search: null,
    }),
  }
</script>

<style lang="scss" scoped>
.skills{
  padding-bottom: 3em;
  margin-left: 1em;
  margin-right: 1em;
}
</style>