<template>
  <section id="education-section">
    <v-container>
      <v-row dense class="d-flex justify-center">
        <v-col :cols="getColsForCard">
          <v-card class="education-card" color="white" elevation="12">
            <v-card-title class="headline">
              <v-icon left>mdi-school</v-icon> Education
            </v-card-title>
            <v-row>
              <v-col
                :cols="getColsForLeftPanelSpacers"
                class="hidden-lg-and-up"
              ></v-col>
              <v-col :cols="getColSizeForLeftPanel" class="image-container">
                <v-img
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="750"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-once="true"
                  :lazy-src="require('../assets/mohamadghali3_lazy.png')"
                  :src="require('../assets/mohamadghali3.png')"
                  class="graduation-image my-3"
                  width="500"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
              <v-col
                :cols="getColsForLeftPanelSpacers"
                class="hidden-lg-and-up"
              ></v-col>
              <v-col :cols="getColSizeForRightPanel">
                <v-card-text class="university-degree">
                  Sept 2015 - June 2019
                </v-card-text>
                <v-card-subtitle class="university-name"
                  >American University of Beirut</v-card-subtitle
                >
                <v-card-text class="university-degree">
                  Bachelor's of Science | Computer Science
                </v-card-text>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="milestone-list-titile"
                        >Milestones Achieved</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="milestone in milestones" :key="milestone">
                    <v-list-item-icon>
                      <v-icon>mdi-trophy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ milestone }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      milestones: [
        "Graduated with Distinction",
        "Won The Best Innovative Development Project Award For My Final Year Project (Mappable)",
        "Listed 7 Times In The Dean's Honor List"
      ]
    };
  },
  computed: {
    getColsForCard() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 10;
        case "xl":
          return 10;
        default:
          return 12;
      }
    },
    getColSizeForLeftPanel() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 4;
        case "xl":
          return 4;
        default:
          return 6;
      }
    },
    getColsForLeftPanelSpacers() {
      switch (this.$vuetify.breakpoint.name) {
        default:
          return 3;
      }
    },
    getColSizeForRightPanel() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 8;
        case "xl":
          return 8;
        default:
          return 12;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.image-container {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.graduation-image {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.university-name {
  font-size: 1.35em;
  font-weight: bold;
}
.university-degree {
  font-size: 1.25em;
}
.education-card {
  margin-top: 1.4em;
  margin-bottom: 2em;
}
.milestone-list-titile {
  font-size: 1.25em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}
#education-section {
  background-color: #385f73;
}
</style>
