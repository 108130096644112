<template>
  <div class="home">
    <NavigationBar></NavigationBar>
    <div class="main-content">
      <WelcomingScreen msg="Welcome to Mohamad El Ghali's portfolio" />
      <Education></Education>
      <WorkExperience></WorkExperience>
      <Portfolio></Portfolio>
      <Skills></Skills>
      <ContactMe @show-snack-bar="showSnackBar"></ContactMe>
    </div>
    <SnackBar
      :visible="visible"
      :message="message"
      @hide="visible = false"
    ></SnackBar>
    <SocialMediaButton></SocialMediaButton>
    <Footer></Footer>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import WelcomingScreen from "@/components/WelcomingScreen.vue";
import Education from "@/components/Education.vue";
import WorkExperience from "@/components/WorkExperience.vue";
import Portfolio from "@/components/Portfolio.vue";
import Skills from "@/components/Skills.vue";
import ContactMe from "@/components/ContactMe.vue";
import SnackBar from "@/components/SnackBar.vue";
import SocialMediaButton from "@/components/SocialMediaButton.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    NavigationBar,
    WelcomingScreen,
    Education,
    WorkExperience,
    Portfolio,
    Skills,
    ContactMe,
    SnackBar,
    SocialMediaButton,
    Footer
  },
  metaInfo() {
    return {
      title: `Mohamad El-Ghali`,
      meta: [
        {
          name:`Description`,
          content: `Mohamad El Ghali is a Software Engineer who studied Computer Science at the American University of Beirut (AUB). He has worked at Cardinal Health (Wavemark) in Zalka Lebanon, AUB Beirut Lebanon, and more. He specializes in web design, web development, and software engineering. He develops E-commerce store online sites, business portfolio sites, and any kind of webapp or website the client demands. PHP, Js, Javascript, JQ, jquery, sql, mysql, psql, Java, html, css, vue, vue.js, bootstrap, python, mohammadghali, mohammadghali97`,
        }
      ]
    };
  },
  data() {
    return {
      visible: false,
      message: ""
    };
  },
  methods: {
    showSnackBar(message) {
      this.message = message;
      this.$nextTick(() => {
        this.visible = true;
      });
    }
  }
};
</script>
<style lang="scss">
* {
  word-break: break-word !important;
}
.prevent-scroll {
  overflow: hidden !important;
}
.row{
  margin: 0px;
}
</style>
