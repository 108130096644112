<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      v-model="show"
      transition="dialog-bottom-transition"
      :class="
        shouldShowFullScreen ? 'full-screen-dialog' : 'none-full-screen-dialog'
      "
      :fullscreen="shouldShowFullScreen"
    >
      <v-card>
        <v-toolbar dark color="pink">
          <v-btn icon dark @click="$emit('hide-dialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ item.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="item-portfolio-body">
          <v-row>
            <v-col :cols="getColSizeForLeftPanel">
              <template>
                <v-carousel hide-delimiters>
                  <template v-for="(mediaItem, i) in item.media">
                    <v-carousel-item
                      :key="i"
                      :ref="`carouselItem${i}`"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    >
                      <v-img
                        class="full-screen"
                        v-if="
                          mediaItem.type === 'image' && isPortfolioDialogVisible
                        "
                        :src="getImgUrl(mediaItem.src)"
                        contain
                      />
                      <div
                        class="full-screen"
                        v-if="
                          mediaItem.type === 'html' && isPortfolioDialogVisible
                        "
                        v-html="mediaItem.src"
                      ></div>
                    </v-carousel-item>
                  </template>
                </v-carousel>
              </template>
              <div class="portfolio-item-content">
                <br />
                <h2>{{ item.name }}</h2>
                <br />
                <div class="item-description" v-html="item.description"></div>
              </div>
            </v-col>
            <v-col :cols="getColSizeForRightPanel">
              <v-card class="mx-auto" max-width="400" tile>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Project Information</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Starting Date</v-list-item-title>
                    <p class="portfolio-item-subtext">{{ item.startingDate }}</p>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>MVP time span</v-list-item-title>
                    <p class="portfolio-item-subtext">{{ item.mvpTimespan }}</p>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Technologies used</v-list-item-title>
                    <p class="portfolio-item-subtext">{{ item.stackUsed }}</p>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    isPortfolioDialogVisible: Boolean,
    item: Object
  },
  watch: {
    isPortfolioDialogVisible() {
      if (this.isPortfolioDialogVisible) {
        console.log('isPortfolioDialogVisible', this.isPortfolioDialogVisible, this.item);
        document.documentElement.style.overflow = "hidden";
        document.body.scroll = "no";
      } else {
        console.log('isPortfolioDialogVisible', this.isPortfolioDialogVisible, this.item);
        document.documentElement.style.overflow = "scroll";
        document.body.scroll = "yes";
      }
    }
  },
  beforeDestroy() {
    document.documentElement.style.overflow = "scroll";
    document.body.scroll = "yes";
  },
  computed: {
    shouldShowFullScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return true;
      }
    },
    getColSizeForLeftPanel() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 9;
        case "xl":
          return 9;
        default:
          return 12;
      }
    },
    getColSizeForRightPanel() {
      switch (this.$vuetify.breakpoint.name) {
        case "lg":
          return 3;
        case "xl":
          return 3;
        default:
          return 12;
      }
    },
    show: {
      get() {
        return this.isPortfolioDialogVisible;
      },
      set(value) {
        if (!value) {
          this.$emit("hide-dialog");
        }
      }
    }
  },
  methods: {
    getImgUrl(imageName) {
      return require(`../assets/${imageName}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.portfolio-item-content {
  margin-top: 10px;
  font-size: 16px;
}
.item-portfolio-body {
  margin-top: 1em !important;
}
.portfolio-item-subtext {
  color: lightslategray;
}
.full-screen {
  height: 100%;
  width: 100%;
}
.full-screen-dialog {
  margin-top: 50px !important;
}
.none-full-screen-dialog {
  margin-top: 20px;
  max-width: 80%;
}
</style>
